import { render, staticRenderFns } from "./RedirectProblems.vue?vue&type=template&id=d96b3582&scoped=true"
import script from "./RedirectProblems.vue?vue&type=script&lang=js"
export * from "./RedirectProblems.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d96b3582",
  null
  
)

export default component.exports