<template>
<div>

</div>
</template>

<script>
import Subject from "../subject/Subject";

export default {
  name: "RedirectProblems",
  async mounted() {
    const params = this.$route.params

    if (params.year) {
      const serie = params.serie || this.$route.query.serie
      const problem_id = params.problem_id || this.$route.query.problem;
      console.log("serie", serie)
      console.log("problem_id", problem_id)
      if (problem_id) {
        await this.$router.push({path: `/selectivitat/${params.subject_name}/examens/${params.year}/${params.mes}/?serie=${serie}&problem=${problem_id}`})
      } else {
        await this.$router.push({path: `/selectivitat/${params.subject_name}/examens/${params.year}/${params.mes}?serie=${serie}`})
      }
    } else if (params.topic_path) {
      // const exams = subject.examsPerTopic.find(t => t.topic_path === params.topic_path);
      // if (exams.problemsOrder && exams.problemsOrder.length) {
      //   const firstProblem = exams.problemsOrder[0];
      //   const problem_id = firstProblem.problem_id
      //   await this.$router.push({path: `/selectivitat/${params.subject_name}/per-temes/${params.topic_path}/${problem_id}`})
      // }
      if (params.problem_id) {
        // http://localhost:8081/selectivitat/Angl%C3%A8s/per-temes/listening-comprehension/4549
        await this.$router.push({path: `/selectivitat/${params.subject_name}/per-temes/${params.topic_path}?problem=${params.problem_id}`})
      } else {

      await this.$router.push({path: `/selectivitat/${params.subject_name}/per-temes/${params.topic_path}`})
      }
    }


  }
}
</script>

<style scoped>

</style>
